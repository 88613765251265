































































import {Component, Watch, Vue} from 'vue-property-decorator';
import UBlock from '@/ui-lib/block/Block.vue';
import UButton from '@/ui-lib/button/Button.vue';
import UAlert from '@/ui-lib/alert/Alert.vue';
import TrackSelectCompilations from '@/components/track/select-compilations/TrackSelectCompilations.vue';
import {CombinationCompilation, CompilationTrackCount} from '@/types';
import {CombinationService, CompilationService} from '@/api';
import equal from 'fast-deep-equal';
import SelectProbabilities, {CompilationProbability} from '@/components/track/select-probabilities/TrackSelectProbabilities.vue';

@Component<CombinationCompilationsEditView>({
    components: {
        UBlock,
        UButton,
        UAlert,
        TrackSelectCompilations,
        SelectProbabilities,
    },

    metaInfo: {
        title: 'Редактирование подборок',
    },

    async beforeRouteEnter(to, from, next) {
        try {
            const combinationId = parseInt(to.params.id, 10);

            const [compilations, trackCount] = await Promise.all([
                CombinationService.compilations(combinationId),
                CompilationService.trackCount(),
            ]);

            next((vm) => {
                vm.compilations = compilations;
                vm.selected = compilations.map((c) => c.compilationId);
                vm.trackCount = trackCount;
            });
        } catch (e) {
            next(e);
        }
    },

    beforeRouteLeave(to, from, next) {
        if (this.saved) {
            next();
        } else {
            if (confirm('Есть не сохраненные настройки. Выйти без сохранения?')) {
                next();
            }
        }
    },
})
export default class CombinationCompilationsEditView extends Vue {
    private compilations: CombinationCompilation[] = [];
    private trackCount: CompilationTrackCount[] = [];

    private selected: number[] = [];
    private probabilities: CompilationProbability[] = [];
    private loading = false;
    private errorMessage = '';

    @Watch('selected')
    private selectedChanged() {
        this.fillProbabilities();
    }

    private get savedProbabilities(): CompilationProbability[] {
        return this.compilations.map((c) => ({
            compilationId: c.compilationId,
            probability: c.probability,
        }));
    }

    private get saved() {
        return equal(this.savedProbabilities, this.probabilities);
    }

    private get totalTrackCount(): number {
        return this.probabilities.reduce((a, p) => a + (this.trackCount.find((c) => c.id === p.compilationId)?.count || 0), 0);
    }

    private fillProbabilities() {
        // delete unused
        for (let i = this.probabilities.length - 1; i >= 0; i--) {
            const probability = this.probabilities[i];

            if (this.selected.includes(probability.compilationId)) {
                continue;
            }

            this.probabilities.splice(i, 1);
        }

        // add new
        for (let i = 0; i < this.selected.length; i++) {
            const compilationId = this.selected[i];
            const find = this.probabilities.find((p) => p.compilationId === compilationId);

            if (find) {
                continue;
            }

            const findLast = this.compilations.find((c) => c.compilationId === compilationId);

            this.probabilities.push({
                compilationId: compilationId,
                probability: findLast?.probability || 100,
            });
        }
    }

    private async save() {
        const combinationId = parseInt(this.$route.params.id, 10);

        this.loading = true;

        try {
            await CombinationService.compilationsSave(combinationId, this.probabilities);

            // load new
            const compilations = await CombinationService.compilations(combinationId);
            this.compilations = compilations;
            this.selected = compilations.map((c) => c.compilationId);

            await this.$router.push({
                name: 'combinationItemCompilationsList',
            });
        } catch (e) {
            this.errorMessage = e?.message || 'Ошибка';
        }

        this.loading = false;
    }
}
