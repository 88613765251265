









































import {Component, Prop, Vue} from 'vue-property-decorator';
import UTable, {TableField} from '@/ui-lib/table/Table.vue';
import {compilation} from '@/store/modules/compilation';
import VueSlider from 'vue-slider-component'

export interface CompilationProbability {
    compilationId: number;
    probability: number;
}

@Component({
    components: {
        UTable,
        VueSlider,
    },
})
export default class extends Vue {
    @Prop({required: true})
    private probabilities!: CompilationProbability[];

    private get fields(): TableField[] {
        return [
            {
                name: 'name',
                label: 'Подборка',
                width: '400px',
            },

            {
                name: 'probability',
                label: '',
            },

            {
                name: 'ipp',
                label: 'ИПР',
                align: 'right',
            },
        ];
    }

    private get compilations() {
        return compilation.context(this.$store).state.list;
    }

    private name(item: CompilationProbability): string {
        return this.compilations.find((c) => c.id === item.compilationId)?.name || '';
    }

    private get sumProbabilities() {
        return this.probabilities.reduce((a, p) => a + p.probability, 0);
    }

    private ipp(item: CompilationProbability): string {
        const p = Math.round(item.probability / this.sumProbabilities * 100);

        return `${p}%`;
    }
}
